<template>
  <div style="margin: 20px" v-loading="isLoading">
    <div class="flex items-center">
      <p class="btn-new-register" @click="addRegistration">New Subscription</p>
      <p class="view_membership_status">Subscription Status <br> <span style="font-weight: bold;font-size: 22px;">{{
        membership_status
          }}</span></p>
      <p style="color: #333;font-size: 14px;line-height: 18px;flex: 1;padding-left: 15px;">EAE Awards operates on a
        subscription system. Before creating any activities, you need to complete the annual subscription. Within one
        subscription year, you can submit four different entries.</p>
    </div>
    <div v-for="(item, index) in dataList" :key="index">
      <Registration :index="index" :info="item" @register="handleRegister(item)" @receipt="handleReceipt(item)"
        @cancel="handleCancel(item)" />
    </div>
    <el-empty description="No Data Yet" v-if="dataList.length === 0"></el-empty>


    <Receipt ref="receipt"></Receipt>

  </div>



</template>

<script>
import {
  getRegistrations,
  updateRegistration,
  checkCurrentYearApplication,
  removeApplication
} from "../../api/eae";
import { getUserId } from "../../utils/store";
import Registration from "../../components/Registration.vue";
import Receipt from '../receipt.vue'

export default {
  name: "index",
  data() {
    return {
      dataList: [],
      isLoading: false,
      membership_status: '',
    };
  },
  components: {
    Registration,
    Receipt
  },
  mounted() {
    this.fetchData();
  },
  methods: {

    async fetchData() {
      let student_id = getUserId()

      let checkResult = await checkCurrentYearApplication(student_id)

      const res = await getRegistrations({ student_id: student_id });
      let result = res.data.data
      let membershipExpiredTime = -1
      result.map(application => {
        if (application.application_payment_date && application.application_payment_date != '') {//已缴费
          let date = new Date(application.application_payment_date + " 00:00:00")
          date.setFullYear(date.getFullYear() + 1)
          var nextYearTimestamp = date.getTime();
          if (membershipExpiredTime < nextYearTimestamp) {
            membershipExpiredTime = nextYearTimestamp
          }
        } else if (application.application_status == '无需缴费') {
          let date = new Date(application.create_time * 1000)
          date.setFullYear(date.getFullYear() + 1)
          var nextYearTimestamp = date.getTime();
          if (membershipExpiredTime < nextYearTimestamp) {
            membershipExpiredTime = nextYearTimestamp
          }
        }
        if (checkResult.data != null && checkResult.data._id.$id == application._id.$id) {
          application.current_application = 'YES'
        } else {
          application.current_application = 'NO'
        }
        return application
      });
      //如果会员过期了，查看有没有待支付订单，当前年的
      let currentTime = new Date().getTime()
      if (membershipExpiredTime < currentTime || membershipExpiredTime == -1) {
        if (checkResult.data != null) {
          this.membership_status = 'Pending Payment'
        } else {
          this.membership_status = 'Expired'
        }
      } else {
        this.membership_status = 'Active'
      }
      this.dataList = result
    },
    editActivity(row) {
      this.$router.push({
        path: "activityEdit",
        query: {
          id: row._id.$id,
        },
      });
    },
    handleRegister(item) {
      this.$router.push({
        path: "/home/payment",
        query: {
          id: item._id.$id,
        },
      });
    },
    async addRegistration() {
      const res = await updateRegistration({
        student_id: getUserId()
      });
      if (res.data.code === 0) {
        this.$message.success("Registration Successful");
        this.fetchData();
      } else {
        this.$message.warning(res.data.msg);
      }
    },
    handleReceipt(item) {
      this.$refs.receipt.open(item._id.$id)
    },
    handleCancel(item) {
      this.$alert('Are you sure you want to cancel this registration?', 'Cancel Registration', {
        confirmButtonText: 'Confirm',
        callback: action => {
          if (action == 'confirm') {
            removeApplication(item._id.$id).then((res) => {
              this.$message.success('Registration Canceled Successfully')
              this.fetchData()
            })
          }
        }
      })
    },
  },
};
</script>

<style scoped>
.btn-new-register {
  text-align: center;
  width: 200px;
  height: 80px;
  background: #ff6450;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.view_membership_status {
  margin-left: 20px;
  text-align: center;
  width: 200px;
  height: 80px;
  background: white;
  border: 1px #ff6450 solid;
  border-radius: 5px;
  color: #ff6450;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
